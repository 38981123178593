@media all and (display-mode: standalone) {
  body {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}

.str1:hover {
  fill: #000000 !important;
}

.str2:hover {
  fill: #000000 !important;
}

.svg-image svg {
  width: 350px;
  height: auto;
}